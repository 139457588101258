import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Products = props => {
  const products = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-team">
      <SEO title="Products" />
      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-left">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">Products</h2>
          </div>
          {products.map(edge => (
            <div
              key={edge.node.frontmatter.path}
              className="col-12 col-md-6 col-lg-4 mb-2"
            >
              <div className="feature">
                {edge.node.frontmatter.image && (
                  <div className="product-image">
                    <img src={edge.node.frontmatter.image} />
                  </div>
                )}
                <h2 className="feature-title">
                  {' '}
                  {/* <Link to={edge.node.frontmatter.path}> */}
                  {edge.node.frontmatter.title}
                  {/* </Link> */}
                </h2>

                <div className="feature-content">{edge.node.excerpt}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProductsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/products/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
            image
          }
        }
      }
    }
  }
`;

export default Products;
